<template>
  <a-card :bordered="false">
    <div class="card-title">检查项目</div>
    <div class="table-operator">
      <a-button v-action:jcxm_add type="primary" icon="plus" @click="handleAdd">添加分类</a-button>
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <s-table
        ref="table"
        rowKey="inspect_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
        tableLayout="auto"
        :scroll="{x:true}"

    >
      <div slot="project_str" slot-scope="text, item">
        <div style="width: 200px;overflow: hidden;white-space: pre-wrap;">{{ text }}</div>
      </div>
      <div slot="status" slot-scope="text, item">
        <a-tag color="green" v-if="text===0">启用</a-tag>
        <a-tag color="red" v-else>停用</a-tag>
      </div>
      <div class="actions" slot="action" slot-scope="text, item">
        <!--        <a v-action:fix_list @cli/ck="handleFixList(item)">确认收到列表</a>-->
        <a v-action:jcxm_edit @click="handleEdit(item)">编辑</a>
        <a v-action:jcxm_delete @click="handleDelete(item)">删除</a>
      </div>
    </s-table>
    <SaveForm ref="SaveForm" @handleSubmit="handleRefresh"/>
  </a-card>
</template>

<script>
import * as Api from '@/api/inspect/inspectCategory'
import {STable} from '@/components'
import SaveForm from './modules/SaveForm.vue'
import SearchForm from './modules/SearchForm.vue'

export default {
  name: 'Index',
  components: {
    STable,
    SaveForm,
    SearchForm,
  },
  data() {
    return {
      // 查询参数
      queryParam: {},
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'ic_id',
        //   width: 60,
        //   align: 'center'
        // },
        {
          title: '分类名称',
          dataIndex: 'category_name',
          width: 200,
          align: 'center',
        },
        {
          width: 100,
          title: '检查内容',
          dataIndex: 'project_str',
          scopedSlots: {customRender: 'project_str'}
        },
        {
          width: 100,
          title: '状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'}
        },
        // {
        //   width: 100,
        //   title: '创建时间',
        //   dataIndex: 'create_time',
        // },
        {
          fixed:'right',
          title: '操作',
          dataIndex: 'action',
          width: 100,
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  created() {

  },
  methods: {
    /**
     * 查看确认列表
     */
    handleFixList(item) {
      this.$refs.FixListModel.queryParam.message_id = item.message_id
      this.$refs.FixListModel.open(item)
    },
    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({ic_id: item['ic_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
